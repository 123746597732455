import React, { useState,useEffect,useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import AuthWrapper from "../AuthWrapper";

const REQUEST_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};

const ForgetPassword = (props) => {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(null);
  const [processing, setprocessing] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setConfirmPassword] = useState("");
  const [confirmPassword, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [msg, setMsg] = useState("");
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator({autoForceUpdate: {forceUpdate: forceUpdate}}))


  

  const handleProcessing = (processing = false, errors = null) => {
    setprocessing(processing);
    setErrors(errors);
  };
  useEffect(() => {
    if (props.location.search.includes("?token")) {
      const params = new URLSearchParams(props.location.search);
      const token = params.get("token");
      setToken(token);
      setStep(2);
    }
  }, []);

  const submitForm = async (request) => {
    const formValid = validator.current.allValid()
    if (formValid) {
      handleProcessing(true);
      const method = request;
      const headers = REQUEST_HEADERS;
      let url = `${REQUEST_BASE_URL}/marketplace/resetpassword`;
      if (method === "PUT") url = `${REQUEST_BASE_URL}/users/resetpassword`;

      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({
          email,
          password,
          token,
          url: props.location.host,
        }),
      });
      if (response.ok) {
        if (method === "POST") {
          setStep(3);
          setMsg("Password reset details are sent to your email at:" + email)
        } else if (method === "PUT") {
          setStep(3);
          setMsg("Password has been reset successfully")
        }

        handleProcessing();
      } else {
        const err = await response.json();
        console.log(err);
        handleProcessing(false, err.msg);
        return;
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <LoadingOverlay>
      <Loader text="Processing ..." loading={processing} />

      <MainLayout>
        <AuthWrapper isSignUp={true}>
          <Seo title="Reset Password" />
          <div className="main">
            <h1 className="d-none">Forget Password</h1>
            <PageHeader title="Forget Password" />
            <Breadcrumb />

            <div className="page-content">
              <div className="container">
                <hr className="mb-4" />
                <div
                  className="login-page  pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/login-bg.jpg)`,
                  }}
                >
                  <div className="container">
                    <div className="form-box">
                      <div className="form-tab">
                        <div className="tab-content">
                          <div>
                            {" "}
                            <h5>Reset your account password</h5>
                            {errors && (
                              <div className="row">
                                <div className="col">
                                  <div
                                    className="alert alert-danger w-100"
                                    role="alert"
                                  >
                                    {typeof errors === "string" && errors}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>{" "}
                          {step === 1 ? (
                            <>
                              <form action="#">
                                <div className="form-group">
                                  <label htmlFor="singin-email-2">
                                    Email address *
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="singin-email-2"
                                    name="email"
                                    onChange={e=>setEmail(e.target.value)}
                                    value={email}
                                    required
                                  />
                                </div>
                              </form>

                              <div className="form-footer">
                                <button
                                  onClick={() => submitForm("POST")}
                                  type="submit"
                                  className="btn btn-outline-primary-2"
                                >
                                  <span>Reset</span>
                                  <i className="icon-long-arrow-right"></i>
                                </button>

                                {/* <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="register-policy-2"
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="register-policy-2"
                                  >
                                    I agree to the{" "}
                                    <a href="pages/login/#a">privacy policy</a>{" "}
                                    *
                                  </label>
                                </div> */}
                              </div>
                            </>
                          ) : step === 2 ? (
                            <>
                              <form action="#">
                                <div className="form-group">
                                  <label htmlFor="singin-email-2">
                                    New Password *
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="singin-email-2"
                                    name="password"
                                    onChange={e=>setPassword(e.target.value)}
                                    value={password}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="singin-email-2">
                                    Confirm Password *
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="singin-email-2"
                                    name="confirmpassword"
                                    onChange={e=>setConfirmPassword(e.target.value)}
                                    value={confirmPassword}
                                    required
                                  />
                                </div>
                              </form>

                              <div className="form-footer">
                                <button
                                  onClick={() => submitForm("PUT")}
                                  type="submit"
                                  className="btn btn-outline-primary-2"
                                >
                                  <span>Reset</span>
                                  <i className="icon-long-arrow-right"></i>
                                </button>

                                {/* <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="register-policy-2"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="register-policy-2"
                              >
                                I agree to the{" "}
                                <a href="pages/login/#a">privacy policy</a>{" "}
                                *
                              </label>
                            </div> */}
                              </div>
                            </>
                          ) : step === 3 ? (
                            <>{msg}</>
                          ) : (
                            "Failed"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AuthWrapper>
      </MainLayout>
    </LoadingOverlay>
  );
};

export default ForgetPassword;
